import React, { useEffect, useRef, useState } from 'react';
import useLocalStorage from '../customHooks/useLocalStorage';
import { useHistory } from "react-router-dom";
import {
    getEntityWithSpeakerId,
    getSelectedSceneFromId,
    isEntityPlayer,
    getDialogHistory,
    myConsoleLog,
    executeNewPlayerAction, getVariableWithName, getVariableWithNamespace, addCustomMessage,
} from '../jsonData/DataWrangler';
import MeldMisstandModel from './MeldMisstandModal';
import WhatsappHeader from './whatsappComponents/WhatsappHeader';
import WhatsappMessage from './whatsappComponents/WhatsappMessage';
import WhatsappOptions from './whatsappComponents/WhatsappOptions';
import { useGeneralStore } from "../Stores/store";
import { useAuth } from "../Auth/auth";
import metadata from './../metadata.json';
import {log} from "@craco/craco/lib/logger";
import inventory from "./Inventory";

export const Scene = ({ selectedId, onChangeScene, addMissionUpdate }) => {
    
    const history = useHistory();

    const [previousSceneLength, setPreviousSceneLength] = useState(0);
    const [storedId, setStoredId] = useState(useLocalStorage('selectedId', selectedId, false));

    const [currentRootNode, setCurrentRootNode] = useState(getSelectedSceneFromId(selectedId != null ? selectedId : storedId[0]));
    const [currentScene, setCurrentScene] = useState([]);

    const [collectablesSelectorVis, setCollectablesSelectorVis] = useState(true);
    
    const auth = useAuth();
    
    useEffect(() => {
        
        auth.isSignedIn();
    }, [])

    // scrolls to the bottom whenever the size of the message array changes
    const messageEndRef = useRef(null);
    useEffect(() => {
        messageEndRef.current?.scrollIntoView({behavior: "smooth" });

        const dialogHistory = getDialogHistory();
        setCurrentScene(dialogHistory)
        const scene = getSelectedSceneFromId(selectedId ? selectedId : storedId[0]);
        setCurrentRootNode(scene);

        // checks the newly added entries for mission updates.
        // if found sends them down the line to the mission update window
        const newAddedAmount = (currentScene.length - previousSceneLength);

        const newlyAdded = currentScene.slice(currentScene.length - newAddedAmount);

        const missionUpdates = newlyAdded.filter(entry => {
            var entity = getEntityWithSpeakerId(entry.Properties.Speaker);
            return entity.Properties.DisplayName === 'Mission update';
        });

        for (let mu in missionUpdates) {
            addMissionUpdate(missionUpdates[mu]);
        }

        for (let n in newlyAdded) {
            const entity = getEntityWithSpeakerId(newlyAdded[n].Properties.Speaker);
            if (!popup && entity.Properties.DisplayName.toLowerCase() === "popup") {
                showPopup(newlyAdded[n].Properties);
                break;
            }

            if (newlyAdded[n].Properties.ExternalId === 'clearinventory') {
                clearInventory();
            }
        }

    }, [currentScene.length]);

    const [setInventoryActive, setInventoryItems, clearInventory] = useGeneralStore(state => [state.setInventoryActive, state.setInventoryItems, state.clearInventory]);
    
    const onClickedDialogueOption = (option) => {

        setPreviousSceneLength(currentScene.length);

        const externalId = option.Properties.ExternalId.toLowerCase().replace(' ', '');

        if (externalId.startsWith('openinventory')) {

            const inventoryItems = getVariableWithName("", option.Properties.MenuText);
            inventoryItems.Description = getVariableWithNamespace("VisibilityCollectables")?.Variables.find(i => i.Variable === inventoryItems.Namespace)?.Description;
            console.log(inventoryItems)

            if (inventoryItems) {
                setInventoryItems(inventoryItems, (a) => {
                    let msgText = "Je hebt gekozen voor:\n";
                    a.map((item, i) => {
                        msgText += "- " + item.Text;
                        if (i < a.length)
                            msgText += "\n";
                    });

                    addCustomMessage(msgText);
                    onChangeScene(executeNewPlayerAction(getSelectedSceneFromId(option.Properties.Id), false));
                })
                setInventoryActive(true, externalId === "openinventorysingle" ? 1 : -1);
                return;
            }
        }
        else if (externalId === 'clearinventory') {
            console.log('encountered inventory clear node!')
            clearInventory();
            return;
        }
        else if (externalId === 'celebrationenable') {
            useGeneralStore.setState({ celebrationEnabled: true });
        }
        else if (externalId === 'celebrationdisable') {
            useGeneralStore.setState({ celebrationEnabled: false });
        }
        else if (externalId === 'gotoscanner') {
            const newScene = executeNewPlayerAction(getSelectedSceneFromId(option.Properties.Id), true);
            newScene.sceneType = "scan";
            onChangeScene(newScene);
            return;
        }
        else if (externalId === 'gotoprofile') {
            const newScene = executeNewPlayerAction(getSelectedSceneFromId(option.Properties.Id), true);
            newScene.sceneType = "profile";
            onChangeScene(newScene);
            return;
        }
        else if (externalId === 'url') {
            const newScene = executeNewPlayerAction(getSelectedSceneFromId(option.Properties.Id), true);
            newScene.sceneType = "url";
            newScene.url = option.Properties.MenuText;
            onChangeScene(newScene);
            return;
        }
        
        const newScene = executeNewPlayerAction(getSelectedSceneFromId(option.Properties.Id), true);
        onChangeScene(newScene);
    }

    let delay = 0;

    const openCollectablesModel = () => {
        setCollectablesSelectorVis(!collectablesSelectorVis);
    }
    
    const [popup, setPopup] = useState(useGeneralStore(state => state.shownPopup));
    const addPopup = useGeneralStore(state => state.addPopup);
    
    useEffect(() => {
        if (popup != null)
            addPopup(popup);
    }, [popup])
    
    const showPopup = (properties) => {

        const popup = {
            id: properties.Id,
            title: properties.MenuText,
            content: properties.Text,
            callback: () => {
                setPopup(null);
            }
        }
        setPopup(popup);
    }

    // determines if a message should be displayed or not
    const shouldBeShown = (scene) => {

        if (scene.Type !== 'DialogueFragment')
            return false;
        
        // if (scene.Properties.ExternalId === "clearinventory")
        //     return false;
        
        return true;
    }

    return (
        <div className="h-screen mx-auto flex flex-col relative overflow-y-auto">

            <WhatsappHeader currentNode={currentRootNode} />

            <div className="overflow-y-auto no-scrollbar flex-col flex-1 p-5 bg-persona-white rounded-2xl shadow-inner">

                {currentScene.map((scene, key) => {
                    
                    if (!shouldBeShown(scene))
                        return null;

                    const isPlayer = isEntityPlayer(scene.Properties.Speaker);
                    const entity = getEntityWithSpeakerId(scene.Properties.Speaker);
                    
                    if (isPlayer === false)
                        delay += 800;
                    else
                        delay = 0;

                    if ((entity === null) || (entity === undefined)) {
                        myConsoleLog("ERROR: null or undefined entity");
                    }

                    return (
                        <WhatsappMessage key={key} index={key} scene={scene} entity={entity} delay={delay} />
                    )
                })}

                <div ref={messageEndRef} />
            </div>

            {
                collectablesSelectorVis === true ? null : <MeldMisstandModel selectedId={selectedId ? selectedId : storedId[0]} />
            }

            <WhatsappOptions currentNode={currentRootNode} onClickedOption={onClickedDialogueOption} delay={delay} openCollectablesModal={openCollectablesModel} />
        </div >
    )
}

export default Scene



