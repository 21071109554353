import React from 'react';
import { useHistory } from "react-router-dom";
import {getNodeWithExternalId, getVariableWithName, getVariableWithNamespace} from "../jsonData/DataWrangler";

import { RiArrowGoBackLine, RiContactsBookLine } from 'react-icons/ri';
import SendProfileEmail from './SendProfileEmail';
import {log} from "@craco/craco/lib/logger";
import useUserStore from "../Stores/userStore";

function getKennisVragenDict(kennisvragen) {

    const vars = getVariableWithNamespace('kennisvragen').Variables;
    const statNames = getVariableWithNamespace('GameSettings').Variables.filter(setting => setting.Variable.includes('statName'));

    let dict = [];

    statNames.forEach(stat => dict.push({ key: stat.Value, value: [] }));

    for (let i = 0; i < vars.length; i++) {
        if (vars[i].Description === "")
            continue;
        
        dict.filter(di => di.key === vars[i].Description)[0]?.value.push(vars[i]);
    }

    return dict;
}

const ProfileScreen = () => {

    let history = useHistory();

    const statName = getVariableWithName("GameSettings", "statName1").Value;
    const statScore = getVariableWithName("playerproperties", statName);

    const profielScore = getVariableWithName("playerproperties", "profielscore");

    // collectables
    const collectablesName = getVariableWithName("GameSettings", "collectablesName").Value;
    const collectables = getVariableWithNamespace(collectablesName);
    const foundCollectables = collectables.Variables.filter(entry => entry.Value.toLowerCase() === 'true').length;
    
    const articyProfile = getVariableWithNamespace(process.env.REACT_APP_PROFILE_IDENTIFIER);
    
    let profile = sortProfile(articyProfile);

    function sortProfile(loadedProfile) {
        let profile = {};
        
        profile.title = getNodeWithExternalId('profileHeaderName')?.Properties.Text || 'Profiel';
        profile.description = loadedProfile.Description;
        profile.rows = [];

        loadedProfile.Variables = loadedProfile.Variables.sort((a,b) => (a.Description.split('|')[0] > b.Description.split('|')[0]) ? 1 : ((b.Description.split('|')[0] > a.Description.split('|')[0]) ? -1 : 0))
        
        for (let i = 0; i < loadedProfile.Variables.length; i++) {
            const descriptionSplit = loadedProfile.Variables[i].Description.split('|');
            
            const index = profile.rows.findIndex(row => row.title === descriptionSplit[2]);

            if (index == -1) {
                const row = {
                    title: descriptionSplit[2],
                    items: [{
                        title: loadedProfile.Variables[i].Variable,
                        value: loadedProfile.Variables[i].Value,
                        visualType: descriptionSplit[1],
                        max: descriptionSplit[3]
                    }],
                    type: loadedProfile.Variables[i].Type,
                };
                profile.rows.push(row);
            }
            else {
                profile.rows[index].items.push({
                    title: loadedProfile.Variables[i].Variable,
                    value: loadedProfile.Variables[i].Value,
                    visualType: descriptionSplit[1],
                    max: descriptionSplit[3]
                })
            }
        }

        return profile;
    }
    

    // user
    const [user] = useUserStore(state => [state.user]);

    let profileCombined = {
        statScore: statScore ? statScore.Value : 0,
        profielScore: profielScore ? profielScore.Value : 0,
        collectables: collectables.Variables,
        foundCollectables: foundCollectables,
    }

    function sortKennisvragen(a, b) {

        const intA = parseInt(a.Variable.replace('vraag', ''));
        const intB = parseInt(b.Variable.replace('vraag', ''));

        if (intA < intB)
            return -1;
        if (intA > intB)
            return 1;
        return 0;
    }

    return (
        <div className="pt-4 flex flex-col h-screen relative">

            <div className="flex items-center w-full h-20 text-xl">
                <p className="w-full text-3xl text-center text-persona-white font-header tracking-wide">
                    { profile.title }
                </p>
            </div>

            <div
                className="text-lg mx-5 font-header text-persona-textDark text-opacity-80 h-full py-2 overflow-y-auto no-scrollbar space-y-3">

                {
                profile &&
                    profile.rows.map((row, key) => {
                        return (
                            <div key={key} className="w-full p-2 rounded-xl bg-persona-white shadow-lg">
                                <div className="flex flex-row">
                                    <p className="w-48 flex-none my-auto mx-2">{row.title}</p>
                                    
                                    
                                    <div className="flex flex-wrap w-full gap-2 items-center">
                                    {
                                        row.items.map((item, itemKey) => {
                                            return (
                                                item.visualType === "block" ?
                                                    <BlockVisual key={itemKey} entry={item} type={row.type} /> :
                                                    <BarVisual key={itemKey} entry={item} />
                                            )
                                        })
                                    }
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

                {/*Kennisvragen*/}
                {/*{kennisVragenDict &&*/}
                {/*    kennisVragenDict.map((kennisVraag, key) => {*/}
                {/*        return (*/}
                {/*            <div key={key} className="w-full p-2 rounded-xl bg-persona-white shadow-lg">*/}
                {/*                <div className="flex flex-row">*/}
                {/*                    <p className="w-32 flex-none my-auto mx-2">{kennisVraag.key}</p>*/}
                {/*                    <div className="flex flex-wrap gap-2">*/}
                {/*                    {kennisVraag.value.map((vraag, key) => (*/}
                {/*                        <VraagVisual key={key} entry={vraag} />*/}
                {/*                    ))}*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        )*/}
                {/*    })*/}
                {/*}*/}

                {/*<div className="relative p-4 pt-2 bg-persona-white rounded-xl shadow-lg">*/}
                {/*    <p className="mr-5 mb-1 text-xl">Gevonden {collectablesName}</p>*/}
                {/*    <div*/}
                {/*        className="relative overflow-hidden h-8 text-sm flex flex-row items-center rounded bg-persona-incompleteMissionText">*/}
                {/*        <div style={{ width: `${collectablesFoundPercentage}%` }}*/}
                {/*            className="shadow-none flex flex-col h-full text-center whitespace-nowrap text-persona-white justify-center bg-gradient-to-r from-persona-greenDark to-persona">*/}
                {/*        </div>*/}
                {/*        <p className="absolute w-full text-center text-persona-white text-lg font-semibold font-sans">{foundCollectables} / {collectables.Variables.length}</p>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <SendProfileEmail profileData={profileCombined} />
            </div>
            
            <div
                className="bg-persona-greenLight my-5 rounded-full mx-auto w-20 h-20 flex items-center shadow-lg cursor-pointer mb-12"
                onClick={() => history.goBack()}>
                <RiArrowGoBackLine className="mx-auto h-12 w-12 m-8 text-persona-textLight" />
            </div>

            {
                user.uuid !== "" &&
                    <div className="absolute bottom-4 w-full text-persona-textLight text-center text-xs">
                        {user.allowedCookies ? "y" : "n"} - {user.uuid.toString()}</div>
            }
        </div>
    )
}

export default React.memo(ProfileScreen)


export const BlockVisual = ({ entry, type }) => {
    
    function answeredStatus(value) {

        // handle booleans
        if (type === "Boolean") {

            if (value.toLowerCase() === 'true') {
                return <div className="h-8 bg-persona-correct rounded-sm"/>
            } else {
                return <div className="h-8 bg-persona-incompleteMissionText rounded-sm"/>
            }
        }
        // handle integers
        else if (type === "Integer") {

            switch (parseInt(value)) {
                case 0:
                    return <div className="h-8 bg-persona-incompleteMissionText rounded-sm"/>
                case 1:
                    return <div className="h-8 bg-persona-correct rounded-sm"/>
                case 2:
                    return <div className="h-8 bg-persona-incorrect rounded-sm"/>
                case 3:
                    return <div className="h-8 bg-persona-orange rounded-sm"/>
                default:
                    return <div className="h-8 bg-persona-incompleteMissionText rounded-sm"/>
            }
        }
    }

    return (
        <div className="w-8 text-center">
            <div className="w-8">
                {
                    answeredStatus(entry.value)
                }
            </div>
        </div>
    )
}

export const BarVisual = ({ entry, type }) => {
    
    const max = parseInt(entry.max);
    const current = parseInt(entry.value);
    const percentage = 100 / max * current;

    return (
        <div className="relative overflow-hidden h-8 text-sm flex w-full flex-row items-center rounded bg-persona-incompleteMissionText">
            <div style={{ width: `${percentage}%` }}
                 className={`shadow-none flex flex-col h-full text-center whitespace-nowrap justify-center bg-gradient-to-r from-persona to-persona ${percentage < 100 && "rounded-r-md"}`}>
            </div>
            <p className="absolute w-full text-center text-persona-textLight text-xl font-semibold font-sans">{current} / {max}</p>
        </div>
    )
}

