import { animated, useTransition, config } from 'react-spring';
import React, { useEffect, useState } from 'react'
import { findImageForElement, isEntityPlayer } from '../../jsonData/DataWrangler';
import useGeneralStore from "../../Stores/store";
import useFeedbackStore from "../../Stores/feedbackStore";
import { MdOutlineStickyNote2 } from 'react-icons/md';
import {useAuth} from "../../Auth/auth";
import useUserStore from "../../Stores/userStore";
import seedrandom from "seedrandom";

// imagePath represents the icon for NPCs
const WhatsappMessage = ({ index, scene, entity, delay }) => {
    
    const [tokenData] = useUserStore(state => [state.tokenData])
    const isPlayer = isEntityPlayer(scene.Properties.Speaker);
    const [sceneRandom, fontSize] = useGeneralStore(state => [state.sceneRandom, state.fontSize]);
    
    const auth = useAuth();

    const transitions = useTransition(true, {

        from: {
            opacity: 0,
            transform: `translateX(${isPlayer ? 100 : -100}%) scaleX(0)`,
        },
        enter: {
            opacity: 1,
            transform: `translateX(0%) scaleX(1)`,
        },
        delay: delay,
        config: config.gentle
    })

    const [editableNote, setEditableNote, hasNote] = useFeedbackStore(state => [state.editableNote, state.setEditableNote, state.hasPreviousNote]);
    const [note, setNote] = useState(null);
    
    useEffect(async () => {
        
        if (auth.tokenData) {
            const result = await hasNote(scene.Properties.Id)
            setNote(result)
        }
    }, [editableNote])
    
    const clickNoteBtn = async (scene) => {
        await auth.isSignedIn();
        setEditableNote(scene);
    }
    
    const createNoteBtn = () => {

        if(!tokenData)
            return;

        if (note == null) {
            return (
                <div className="opacity-50 hover:opacity-100 absolute -top-2 -right-6 w-10 h-10 bg-persona-greenDark rounded-full shadow-md cursor-pointer flex flex-row items-center justify-center"
                     onClick={() => clickNoteBtn(scene)} >
                    <MdOutlineStickyNote2 className="text-persona-textLight w-8 h-8"/>
                </div>
            )
        }
        else {
            return (
                <div className="opacity-100 hover:opacity-100 absolute -top-2 -right-6 w-10 h-10 bg-persona-greenDark rounded-full shadow-md cursor-pointer flex flex-row items-center justify-center"
                     onClick={() => clickNoteBtn(scene)} >
                    <MdOutlineStickyNote2 className="text-persona-textLight w-8 h-8"/>
                </div>
            )
        }
    }

    function getDisplayText(input) {
        
        if (process.env.REACT_APP_RANDOM_TEXT_FEATURE !== 'true') {
            return input;
        }
        else {
            if (!input.includes('|')) {
                return input;
            }
            else {
                const options = input.split('|');
                const seededRng = seedrandom(index.toString() + sceneRandom);
                const rnd = Math.floor(seededRng() * options.length);
                return options[rnd];
            }
        }
    }

    if (isPlayer) {
        return transitions(
            (styles, item) => item && <animated.div style={styles} className="flex flex-row justify-end space-x-2 text-persona-textLight text-sm">
                <div className={`flex flex-col p-2 rounded-2xl rounded-tr-none shadow-message ml-10 relative mb-5 bg-gradient-to-r from-persona-highlight to-persona-highlightDark`}>
                    <p className="mx-2 my-1 whitespace-pre-line" style={{fontSize: fontSize + "rem", lineHeight: fontSize + 0.5 + "rem"}}>{getDisplayText(scene.Properties.Text)}</p>

                    <svg height="12" width="12" fill="none" stroke="currentColor" className="absolute top-0 -right-3 text-persona-highlightDark fill-current">
                        <polygon points="0,0 12,0 0,12" className=""/>
                    </svg>
                    
                    {createNoteBtn()}
                </div>
            </animated.div>
        )
    }
    else {
        
        // render description messages
        if (entity.Properties.DisplayName === "Description")
        {
            return transitions(
                (styles, item) => item && <animated.div style={styles} className="flex flex-row justify-center space-x-2 text-persona-textDark text-sm">

                    <div className={`flex flex-col p-2 rounded-md shadow-message border-2 border-black border-opacity-10 relative mb-5 bg-persona-incompleteMissionText bg-opacity-10 text-center`}>
                        <p className="mx-2 my-1 whitespace-pre-line" style={{fontSize: fontSize + "rem", lineHeight: fontSize + 0.5 + "rem"}}>{getDisplayText(scene.Properties.Text)}</p>
                        {createNoteBtn()}
                    </div>
                </animated.div>
            )
        }
        else
        {
            let imagePath = findImageForElement(entity);

            if (imagePath !== null)
                imagePath = require(`../../${imagePath}`).default

            return transitions(
                (styles, item) => item && <animated.div style={styles} className="flex flex-row justify-start space-x-2 text-persona-textLight text-sm">

                    <div className={`flex flex-col p-2 rounded-2xl rounded-tl-none shadow-message mr-10 relative mb-5 bg-gradient-to-r from-persona-greenMiddle to-persona`}>

                        <svg height="12" width="12" fill="none" stroke="currentColor" className="absolute top-0 -left-3 text-persona-greenMiddle fill-current">
                            <polygon points="0,0 12,0 12,12" className=""/>
                        </svg>

                        <div className="flex flex-row items-center">
                            {imagePath !== null ? <div className="flex-none w-8 h-8 border border-white bg-white rounded-full overflow-hidden">
                                <img src={imagePath} alt="" className="" />
                            </div> : null}
                            <p className="mx-2 text-xs opacity-50" style={{fontSize: fontSize - 0.2 + "rem", lineHeight: fontSize + 0.5 + "rem"}}>{entity.Properties.DisplayName}</p>
                        </div>
                        
                        <p className="mx-2 my-1 whitespace-pre-line" style={{fontSize: fontSize + "rem", lineHeight: fontSize + 0.5 + "rem"}}>{getDisplayText(scene.Properties.Text)}</p>

                        {createNoteBtn()}
                    </div>
                </animated.div>
            )
        }
    }
}

export default WhatsappMessage;