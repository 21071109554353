import React from 'react';
import { useHistory } from "react-router-dom";

import { RiArrowGoBackLine } from 'react-icons/ri';
import { getNodeWithExternalId } from '../jsonData/DataWrangler';

const Guide = () => {

    const node = getNodeWithExternalId('speluitleg');
    
    let history = useHistory();

    return (
        <div className="px-5 pb-8 pt-4 flex flex-col h-screen relative text-persona-textLight">
            <div className="flex items-center w-full h-24 text-xl">
                <p className="w-full text-3xl text-center font-header tracking-wide">
                    Speluitleg
                </p>
            </div>
            <div className="h-full overflow-y-auto mb-5 px-12 py-12 bg-persona-white rounded-2xl shadow-md text-sm text-persona-textDark">
                <div className="text-lg leading-relaxed whitespace-pre-wrap">
                    {node != null
                        ? <>{node.Properties.Text}</>
                        : <>[FlowFragment node met externalId 'speluitleg' mist in json]</>
                    }
                </div>
            </div>

            <div className="bg-persona-greenLight rounded-full mx-auto w-20 h-20 flex items-center shadow-lg cursor-pointer" onClick={() => history.goBack()}>
                <RiArrowGoBackLine className="mx-auto h-12 w-12 m-8 text-persona-white" />
            </div>
        </div>
    )
}

export default Guide
