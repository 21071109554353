import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './App.css';
import Main from './components/Main';
import MissionUpdate from './components/MissionUpdate';
import { loadDataFromJson } from './jsonData/DataWrangler';

import BackgroundDecorations from './components/BackgroundDecorations';
import { useGeneralStore } from "./Stores/store";
import { useUserStore } from "./Stores/userStore";
import { useFeedbackStore } from "./Stores/feedbackStore";
import Popup from "./components/Popup";
import NoteEditor from "./components/debug/NoteEditor";
import Inventory from "./components/Inventory";
import CookieModal from "./components/CookieModal";
import SaveStateManager from "./components/debug/SaveStateManager";

import Confetti from 'react-confetti'
import useWindowDimensions from "./Misc/UseWindowDimensions";

const App = () => {

  const [data, setLoadedData] = useState();

  // very important! Loads the data from either the localstorage or the original file.
  useEffect(async () => {
    await FetchData();

    //setupProfile();

    //console.log(useProfileStore.getState().profile);
  }, [])
  
  const FetchData = async () => {
    
      await fetch(`${document.location.origin}/data/data.json`
          , {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          }
      )
      .then((response) => {
        if (response.ok) {
          if (process.env.REACT_APP_LOG_TO_CONSOLE)
            console.log(`SUCCES: Loaded data from ${document.location.origin}/data/data.json`);
          return response.json();
        }
        else {
          if (process.env.REACT_APP_LOG_TO_CONSOLE) {
            console.log(`WARNING: Could not load data from ${document.location.origin}/data/data.json`);
            showError(`WARNING: Could not load data from data/data.json`);
          }
          return null;
        }
      })
      .then((jsonResponse) => {

        setLoadedData(loadDataFromJson(jsonResponse));
      })
      .catch((error) => {
        console.log("ERROR: " + error);
      })
  }

  let history = useHistory();

  const [currentScene, setCurrentScene] = useState(null);

  const changeScene = (newScene) => {

    if (newScene.sceneType === '/') {
      history.replace('/');
    }
    else if (newScene.sceneType === 'scan') {
      history.replace('/scan');
    }
    else if (newScene.sceneType === 'url') {
      window.open('https://' + newScene.url, '_blank', 'noopener,noreferrer');
    }
    else if (newScene.sceneType === 'scene') {
      if (currentScene === null || currentScene.sceneType !== newScene.sceneType)
        history.replace('/scene');
    }

    setCurrentScene(newScene);
  }

  const resetCurrentScene = () => {
    setCurrentScene(null);
  }

  const [pendingMissionUpdates, updatePendingMissionUpdates] = useState([]);

  const addMissionUpdate = (missionUpdate) => {

    if (pendingMissionUpdates.some(update => update.Properties.Id === missionUpdate.Properties.Id)) {
      //console.log("Exists already!");
      return;
    }

    updatePendingMissionUpdates(previousArr => [...previousArr, missionUpdate]);
  }

  const dismissMissionUpdate = (missionUpdate) => {

    const index = pendingMissionUpdates.indexOf(missionUpdate);
    if (index > -1) {
      updatePendingMissionUpdates(pendingMissionUpdates.filter((entry) => entry !== missionUpdate));
    }
  }

  // user
  const [user] = useUserStore(state => [state.user]);
  
  // popups
  const popup = useGeneralStore(state => state.shownPopup);
  const [editableNote] = useFeedbackStore(state => [state.editableNote]);
  
  // errors
  const shownError = useGeneralStore(state => state.shownError);
  const showError = useGeneralStore(state => state.showError);
  const removeError = useGeneralStore(state => state.removeError);
  
  // inventory
  const inventoryShown = useGeneralStore(state => state.inventoryShown)
  const inventoryItems = useGeneralStore(state => state.inventoryItems);

  const celebrationEnabled = useGeneralStore(state => state.celebrationEnabled);

  const { height, width } = useWindowDimensions();
  
  const dismissError = () => {
    removeError();
  }

  if (!data) {
    return (
      <div className="max-w-screen-sm m-auto">
        <BackgroundDecorations />
        <p className="absolute font-header text-3xl h-full w-full text-center text-persona-white">Loading...</p>
      </div>
    )
  }
  else {
    return (
      <>
        {
            celebrationEnabled &&
            <Confetti
                width={width}
                height={height}
                numberOfPieces={600}
                gravity={0.02}
            />
        }
        
        <Main onChangeScene={changeScene} resetCurrentScene={resetCurrentScene} addMissionUpdate={addMissionUpdate} />

        <MissionUpdate pendingMissionUpdates={pendingMissionUpdates} dismissMissionUpdate={dismissMissionUpdate} />
        
        {
          shownError &&
              <div className="z-50 w-full py-2 bg-opacity-50 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
                onClick={dismissError}>
                <div className="bg-red-800 text-white text-center">Error encountered: {shownError}</div>
              </div>
        }
        
        {
          popup &&
            <Popup details={popup}/>
        }

        {
          editableNote &&
            <NoteEditor editableNote={editableNote}/>
        }

        {
          inventoryShown &&
            <Inventory inventoryItems={inventoryItems}/>
        }

        {
          user.prompted === false && process.env.REACT_APP_DISPLAY_COOKIE_MODAL === "true" &&
            <CookieModal/>
        }

        {
          process.env.REACT_APP_SAVE_STATES === "true" &&
            <SaveStateManager/>
        }
      </>
    )
  }
}

export default App;
