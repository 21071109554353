import create from 'zustand'
import produce from "immer";
import { getArrayByType, getJsonData } from "../jsonData/DataWrangler";

const getLocalStorage = (key) => JSON.parse(window.localStorage.getItem(key));
const setLocalStorage = (key, value) => window.localStorage.setItem(key, JSON.stringify(value));

const profileObjectDefinitionName = "Profiel";
const templateName = "Basic_Persona_Profile";

let profileConstraints = null; 
let profileProperties = null;

const mockSetProp = 'setProp(getObj(\"Hub_E6C02385\"), \"Basic_Profile_Properties.BooleanValue\", true)';
const mockGetProp = 'getProp(getObj(\"Profile_Hub_E6C02385\"), \"Basic_Persona_Profile.categorie01_01_vraag1\")==true';

const jsonProfileDataPath = `${document.location.origin}/data/profile.json`;

const useProfileStore = create((set, get) => ({

    profile: {},
    
    setupProfile: () => set(async state => {
        const response = await fetch(jsonProfileDataPath);
        const data = await response.json();

        if (data != null)
            state.profile = data;
        
        console.log("Profile fetched and set-up, ready to use!!!")
        console.log(state.profile);
    }),
    
    findProp: (expression) => set(state => {
        
        const parts = expression.split('.')
        //console.log(parts)
        
        console.log(get().profile)

        // Object.entries(state.profile).forEach(
        //     ([key, value]) => console.log(key, value)
        // );
    }),
    
    // setupProfile: () => set(state => {
    //     let profile = {};
    //
    //     let objectDefinition = getJsonData().ObjectDefinitions.find(o => o.Type === profileObjectDefinitionName);
    //     if (objectDefinition == null) {
    //         console.error("No object defined with name: ", profileObjectDefinitionName);
    //         return;
    //     }
    //
    //     profileConstraints = objectDefinition.Template.Features[0].Constraints;
    //     profileProperties = objectDefinition.Template.Features[0].Properties;
    //
    //     const profielTemplate = getArrayByType('Profiel')[0].Template[templateName];
    //     for (const [key, value] of Object.entries(profielTemplate)) {
    //
    //         const splitKey = key.split("_");
    //         const category = splitKey[0];
    //         const order = splitKey[1] === "description" ? 0 : parseInt(splitKey[1]) || 0;
    //
    //         const od = profileProperties.find(o => o.Property === key);
    //
    //         // if non-existent
    //         if (profile[category] == null) {
    //
    //             profile[category] = {
    //                 name: category,
    //                 items: []
    //             };
    //         }
    //
    //         // add description if found
    //         if (splitKey[1].toLowerCase() === "description") {
    //             profile[category].description = value;
    //             continue;
    //         }
    //
    //         // add data from matching category
    //         let item = {
    //             Name: key,
    //             Value: value,
    //             Category: category,
    //             Order: order,
    //             DefaultValue: value,
    //             Type: od.Type.toLowerCase(),
    //             DisplayName: od.DisplayName,
    //         }
    //
    //         addAdditionalInfo(item);
    //
    //         profile[category].items.push(item);
    //
    //         if (profile['all'] == null)
    //             profile['all'] = [];
    //         profile['all'].push(item);
    //     }
    //
    //     state.profile = profile;
    //
    //     console.log("Profile fetched and set-up, ready to use!!!")
    //     console.log(profile)
    // }),

    // setProp(getObj(\"Hub_E6C02385\"), \"Basic_Profile_Properties.BooleanValue\", true)
    setProp: (expression) => set(state => {
        
        if (expression == null)
            expression = mockSetProp;
        
        console.log("setting prop", expression);
    }),

    // getProp(getObj(\"Profile_Hub_E6C02385\"), \"Basic_Persona_Profile.categorie01_01_vraag1\")==true
    getProp: (expression) => set(state => {

        if (expression == null)
            expression = mockGetProp;
        
        // find the comparator and checkvalue
        const regex = /([^)]*)$/;
        const found = expression.match(regex);

        // remove unnecessary characters
        expression = expression.replaceAll(found[0], "");
        expression = expression.replaceAll('\"', "");
        expression = expression.replaceAll('getProp', "");
        expression = expression.replaceAll('getObj', "");
        expression = expression.replaceAll(' ', "");
        expression = expression.replaceAll('(', "");
        expression = expression.replaceAll(')', "");
        
        let split = expression.split(',')[1].split('.');
        split.push(found[0].substring(0, 2));
        split.push(found[0].substring(2));

        console.log(split)

        const value = state.profile['all'].find(o => o.Name === split[1])
        const valueType = value.Value;
        console.log(valueType)

        switch (split[2]) {
            case "==":
                console.log("Is equal to")
                break;
            case "!=":
                console.log("Is not equal to")
                break;
            case "<":
                console.log("Is smaller than")
                break;
            case ">":
                console.log("Is greater than")
                break;
            case "<=":
                console.log("Is smaller or equal to")
                break;
            case ">=":
                console.log("Is greater or equal to")
                break;
        }
    })
}));


const addAdditionalInfo = (element) => {

    const matchingConstraint = profileConstraints.find(o => o.Property === element.Name);
    if (matchingConstraint == null)
        return;

    if (matchingConstraint.Type.toLowerCase() === "number") {
        element.MinValue = parseFloat(matchingConstraint.MinValue);
        element.MaxValue = parseFloat(matchingConstraint.MaxValue);
    }
}

export default useProfileStore;

