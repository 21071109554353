import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { getMissionArray, findIDGivenName, getSelectedSceneFromId } from '../jsonData/DataWrangler';
import { AiOutlineQrcode } from 'react-icons/ai';
import { BsQuestionCircle, BsGraphUp, BsFillBasketFill } from 'react-icons/bs';
import { GoSignOut } from 'react-icons/go';

import metadata from '../metadata.json';
import screenfull from 'screenfull';

import { getVariableWithName, getVariableWithNamespace } from "../jsonData/DataWrangler";
import { useAuth } from "../Auth/auth";
import useProfileStore from "../Stores/profileStore";

const HomeScreen = ({ handleScanShortcut, resetCurrentScene }) => {

    const [missions, setMissions] = useState([]);
    const auth = useAuth();

    const title = getVariableWithName("GameSettings", "title")?.Value ?? metadata.title;
    const statName = getVariableWithName("GameSettings", "statName1")?.Value;
    const statScore = getVariableWithName("playerproperties", statName);
    
    const visibilityMissions = getVariableWithNamespace("VisibilityMission")?.Variables;

    const introChatVars = getVariableWithNamespace("Vis_ConversationsShortcuts")?.Variables;
    const introChatIDs = [];
    const introChatVis = [];
    
    const [setupProfile, findProp, getProp, setProp] = useProfileStore(state => [state.setupProfile, state.findProp, state.getProp, state.setProp]);

    if (introChatVars) {
        for (let i = 0; i < introChatVars.length; i++) {
            const name = findIDGivenName(introChatVars[i].Value, false, false);
            const id = name ? (getSelectedSceneFromId(name).Properties.Id).slice(-4) : null;
            
            // removed because if empty it will miss-align the indices
            //if (id) {
                introChatIDs.push(id);
                
                const visibility = getVariableWithName("VisibilityConversations", introChatVars[i].Variable)?.Value;
                if (visibility) {
                    introChatVis.push(visibility.toLowerCase());
                }
                else {
                    introChatVis.push('false');
                }
            //}
        }
    }

    const [ping, setPing] = useState(true);

    useEffect(() => {
        setMissions(getMissionArray());
        resetCurrentScene();
        if (process.env.REACT_APP_FEEDBACK_VERSION === 'true')
            auth.isSignedIn();
    }, [])

    function goFullScreen() {
        screenfull.request();
    }

    return (
        <div className="relative">
            <div className="pt-6 px-5 relative text-persona-textDark">
                <p className="text-center text-4xl text-persona-textLight font-header">{`${title}`}</p>
                <p className="text-center text-xs text-persona-textLight mb-4 opacity-70">{`${metadata.buildMajor}.${metadata.buildMinor}`}</p>

                <div className="relative">
                    <Link to="/uitleg" className="persona-btn persona-btn-hover mb-3 pl-6 flex">
                        <div className="flex-1 m-auto text-2xl">Speluitleg</div>
                        <BsQuestionCircle className="h-10 w-10 mr-1" />
                    </Link>
                </div>

                <div className="bg-persona-white px-5 pb-3.5 rounded-xl mb-3 pt-3 shadow-xl font-header">
                    <div className="flex flex-row pb-2">
                        <p className="flex-1 text-left text-3xl text-persona-textDark text-opacity-80">Missies</p>
                        {statScore &&
                            <p className="flex-0 text-right text-lg mt-auto">{statName} {statScore.Value}</p>
                        }
                
                    </div>
                    {/* render mission buttons */}
                    {missions.map((mission, key) => {
                        return (
                            visibilityMissions?.filter(m => m.Variable === mission.Namespace)[0]?.Value.toLowerCase() === 'true' &&
                                <MissionButton mission={mission} key={key} />
                        )
                    })}
                </div>

                {
                    introChatIDs.map((shortcut, key) => {
                        if (introChatVis[key] === 'true') {
                            return (
                                <div key={key} className="relative cursor-pointer" onClick={() => { handleScanShortcut(shortcut) }}>
                                    <div className="flex flex-row items-center persona-btn persona-btn-hover mb-3 p-2 pl-6">
                                        <div className="flex-1 m-auto text-lg">{introChatVars[key].Value}</div>
                                        {ping === true ? <div className="w-4 h-4 rounded-full shadow-lg bg-persona-highlight animate-ping mr-6"></div> : null}
                                    </div>
                                </div> 
                            )
                        }
                    })
                }

                <ShortcutButtonRow />

                { process.env.REACT_APP_SHOW_VARIABLE_EDITOR === 'true' &&
                    <Link to="/vars" className="persona-btn persona-btn-hover my-3 pl-6 flex">
                        <div className="flex-1 m-auto text-2xl">Variable Editor</div>
                    </Link>
                }

                { process.env.REACT_APP_FEEDBACK_VERSION === 'true' &&
                    <Link to="/account" className="persona-btn persona-btn-hover my-3 pl-6 flex items-center">
                        {
                            auth.tokenData ?
                                <>
                                    <div className="flex-1 m-auto mr-2">
                                        <div className="text-sm">Ingelogd als {auth.tokenData.email}</div>
                                        <p className="text-base">Klik om naar account pagina te gaan</p>
                                    </div>
                                    <GoSignOut className="h-full w-8"/>
                                </> :
                                <>
                                    <div className="flex-1 m-auto text-2xl">Login</div>
                                </>
                        }
                    </Link>
                }

                <button onClick={goFullScreen} className="relative w-full text-opacity-75 mt-5 underline mb-8 text-persona-textLight">
                    {screenfull.isFullscreen === true ? 'Sluit volledig scherm' : 'Volledig scherm'}
                </button>
            </div>
        </div>
    )
}

const MissionButton = ({ mission }) => {

    const totalSteps = mission.Variables.length;
    const stepsCompleted = mission.Variables.filter(variable => variable.Value.toLowerCase() === "true");
    
    const firstAvailableDescription = mission.Variables.find(m => m.Value.toLowerCase() === "false")?.Description || "Je hebt deze missie voltooid";

    return (
        <div className="relative">
            <Link to={`/mission/${mission.Namespace}`} className="persona-btn-hover relative pl-6 font-header text-persona-textLight mb-1.5 p-3 rounded-lg shadow-md flex bg-gradient-to-r from-persona-greenMiddle to-persona">
                <p className="flex-1 tracking-wider">{firstAvailableDescription}</p>
                <p className="mr-2">{stepsCompleted.length} / {totalSteps}</p>
            </Link>
        </div>
    )
}

export default HomeScreen

const ShortcutButtonRow = () => {

    return (
        <div className="flex flex-row text-center space-x-1 shadow-lg text-lg font-bold text-persona-textDark text-opacity-80">
            <Link to="/scan" className="flex-grow rounded-l-xl py-3 bg-persona-white flex-col transform transition duration-200 hover:scale-105 hover:-translate-y-0.5 hover:shadow-agressive z-0 hover:z-10">
                <AiOutlineQrcode className="h-12 w-12 w-full" />
                <p className="">Scanner</p>
            </Link>

            <Link to="/collectables" className="flex-grow py-3 bg-persona-white transform transition duration-200 hover:scale-105 hover:-translate-y-0.5 hover:shadow-agressive z-0 hover:z-10">
                <BsFillBasketFill className="h-12 w-12 w-full" />
                <p className="">Bevindingen</p>
            </Link>

            <Link to="/profile" className="flex-grow rounded-r-xl py-3 bg-persona-white transform transition duration-200 hover:scale-105 hover:-translate-y-0.5 hover:shadow-agressive z-0 hover:z-10">
                <BsGraphUp className="h-12 w-12 w-full" />
                <p className="">Profiel</p>
            </Link>
        </div>
    )
}